import BfpCalculator from "../../../../src/components/fitness-calculators/bfp";
import FfmCalculator from "../../../../src/components/fitness-calculators/ffm";
import RmrCalculator from "../../../../src/components/fitness-calculators/rmr";
import TefCalculator from "../../../../src/components/fitness-calculators/tef";
import NeatCalculator from "../../../../src/components/fitness-calculators/neat";
import NeeCalculator from "../../../../src/components/fitness-calculators/nee";
import EratCalculator from "../../../../src/components/fitness-calculators/erat";
import TedCalculator from "../../../../src/components/fitness-calculators/ted";
import MacrosCalculator from "../../../../src/components/fitness-calculators/macros";
import MassInput from "../../../../src/components/physics/mass-input";
import DistanceInput from "../../../../src/components/physics/distance-input";
import * as React from 'react';
export default {
  BfpCalculator,
  FfmCalculator,
  RmrCalculator,
  TefCalculator,
  NeatCalculator,
  NeeCalculator,
  EratCalculator,
  TedCalculator,
  MacrosCalculator,
  MassInput,
  DistanceInput,
  React
};